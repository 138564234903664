import { AdminComponent } from './admin/admin.component';
import { VisitsViewComponent } from './visits/visits-view/visits-view.component';
import { UsersViewComponent } from './users/users-view/users-view.component';
import { CustomersComponent } from './customers/customers-view/customers.component';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AuthGuard } from './_guards/auth.guard';
import { NewCustomerComponent } from './customers/customer-new/new-customer.component';
import { CustomerGroupsComponent } from './groups/groups-view/customer-groups.component';
import { TasksViewComponent } from './tasks/tasks-view/tasks-view.component';

const appRoutes: Routes = [
    { path: 'login', component: LoginComponent},
    { path: 'home', component: HomeComponent},
    { path: 'register', component: RegisterComponent},
    { path: 'AddCustomer', component: NewCustomerComponent, canActivate: [AuthGuard]},
    { path: 'admin', component: AdminComponent, canActivate: [AuthGuard]},
    { path: 'customers', component: CustomersComponent, canActivate: [AuthGuard]},
    { path: 'customerGroups', component: CustomerGroupsComponent, canActivate: [AuthGuard]},
    { path: 'users', component: UsersViewComponent, canActivate: [AuthGuard]},
    { path: 'tasks', component: TasksViewComponent, canActivate: [AuthGuard]},
    { path: 'visits', component: VisitsViewComponent, canActivate: [AuthGuard]},
    { path: '', redirectTo: '/visits', pathMatch: 'full' },
    { path: '**', redirectTo: '/visits'}
];

export const routing = RouterModule.forRoot(appRoutes);
