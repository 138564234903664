import { Group } from './../../_models/group.model';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { GroupsService } from '../../_services/groups/groups.service';
import { AlertService } from '../../_services';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { group } from '@angular/animations';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DeleteQuestionDialogComponent } from '../../delete-question-dialog/delete-question-dialog.component';

@Component({
  selector: 'app-groups-edit',
  templateUrl: './groups-edit.component.html',
  styleUrls: ['./groups-edit.component.css']
})
export class GroupsEditComponent implements OnInit {

  // Defininig the form
  form = new FormGroup({
    id: new FormControl('', [
      Validators.required,
    ]),
    name: new FormControl('', [
      Validators.required,
    ]),
    color: new FormControl('', [
      Validators.nullValidator
    ]),
    comments: new FormControl('', [
      Validators.nullValidator
    ]),
  });

  // Used to disable the submit button on click
  submitting = false;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<GroupsEditComponent>,
    private service: GroupsService,
    private alertService: AlertService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loadGroup();
  }

  loadGroup() {
    this.loadingSubject.next(true);

    this.service.get(this.data.id)
    .pipe(
        catchError(() => of()),
        finalize(() => this.loadingSubject.next(false))
    )
    .subscribe(g => {
        this.form.setValue(g);
    });
  }

  onSubmit(model: Group, isValid: boolean) {

    if (!isValid) {
      this.alertService.error('Please fill the required fields');
      return;
    }

    this.submitting = true;

    this.service.update(model.id, model).subscribe(res => {
      this.alertService.success('Group Updated');
      this.data.dataSource.load();
      this.dialogRef.close();
    },
    error => {
      this.alertService.error(error.statusText);
    });
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onDeleteClick(model: Group) {
    // Open the question dialog
    const dialogRef = this.dialog.open(DeleteQuestionDialogComponent, {
      width: '250px',
      data: {
        id: model.id,
        dataSource: this.data.dataSource,
        service: this.service,
        dialog: this.dialogRef
      }
    });
  }
}
