import { Router, RouterStateSnapshot } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { User } from '../_models/user';
import { UserService, AuthenticationService } from '../_services';
import { Observable } from 'rxjs';
import { UserIconDialogComponent } from './user-icon-dialog/user-icon-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  currentUser: User;
  userImageUrl: string;
  screenWidth: number;
  isLoggedIn$: Observable<boolean>;

  constructor(
    private auth: AuthenticationService,
    private router: Router,
    public dialog: MatDialog) {
      this.screenWidth = window.innerWidth;
      window.onresize = () => {
        this.screenWidth = window.innerWidth;
      };
      router.events.subscribe(r => {
        if (this.currentUser === null) {
          this.currentUser = this.auth.getCurrentUser();
        }
    });
   }

  ngOnInit() {
    this.isLoggedIn$ = this.auth.isLoggedIn;
    this.currentUser = this.auth.getCurrentUser();
  }

  logout() {
    // remove local storage object
    this.auth.logout();
    this.router.navigate(['/login']);
    window.location.reload();
  }

  openUserIconDialog() {
    this.dialog.open(UserIconDialogComponent, {
      width: '315px',
      height: '180px',
      maxWidth: '315px',
      maxHeight: '180px',
      backdropClass: 'user-backdrop',
      panelClass: 'user-dialog',
      position: {
        top: '60px',
        right: '10px',
      }
    });
  }

}
