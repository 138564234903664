import { CustomersDataSource } from './customers.datasource';
import { Component, OnInit, Inject } from '@angular/core';
import {MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { CustomerService } from '../../_services/customers.service';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { NewCustomerComponent } from '../customer-new/new-customer.component';
import { CustomerEditComponent } from '../customer-edit/customer-edit.component';
import { DeleteQuestionDialogComponent } from '../../delete-question-dialog/delete-question-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {

  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);
  displayedColumns: string[] = ['name', 'address', 'emails', 'active', 'visits'];

  smallTableSubscription = this.isExtraSmall.subscribe(result => {
    if (result.matches) {
      this.displayedColumns = ['name', 'active', 'visits'];
    } else {
      this.displayedColumns = ['name', 'emails', 'active', 'visits'];
    }
  });

  dataSource: CustomersDataSource;

  constructor(private breakpointObserver: BreakpointObserver,
              private service: CustomerService,
              public dialog: MatDialog,
              public router: Router) { }

  ngOnInit() {
    this.dataSource = new CustomersDataSource(this.service);
    this.dataSource.load();
  }

  // Open the Add Form Dialog
  onAddClick() {
    const dialogRef = this.dialog.open(NewCustomerComponent, {
      width: '700px',
      height: '800px',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: { dataSource: this.dataSource }
    });

    const smallDialogSubscription = this.isExtraSmall.subscribe(result => {
        if (result.matches) {
          dialogRef.updateSize('100%', '100%');
        } else {
          dialogRef.updateSize('700px', '800px');
        }
    });

    dialogRef.afterClosed().subscribe(result => {
      smallDialogSubscription.unsubscribe();
    });
    // this.router.navigate(['/AddCustomer']);

  }

  onRowDeleteClick(row) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(DeleteQuestionDialogComponent, {
      width: '250px',
      data: {
        id: row.id,
        dataSource: this.dataSource,
        service: this.service
      }
    });
  }

  // Open the Edit Form dialog
  onRowEditClick(row) {
    const dialogRef = this.dialog.open(CustomerEditComponent, {
      width: '700px',
      height: '800px',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: {id: row.id, dataSource: this.dataSource}
    });

    const smallDialogSubscription = this.isExtraSmall.subscribe(result => {
      if (result.matches) {
        dialogRef.updateSize('100%', '100%');
      } else {
        dialogRef.updateSize('700px', '800px');
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      smallDialogSubscription.unsubscribe();
    });
  }

  onRow(row) {
  }
}
