import { Visit } from './../_models/visit';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as globals from './../globals';

@Injectable({
  providedIn: 'root'
})
export class VisitsService {
    private _url = `${globals.API_URL}/visits`;
    private finalUrl:string = '';

    constructor(private http: HttpClient) { }

    // Returns all Visits
    getAll(pageIndex: number, pageSize: number,shortBy: string = '',shortOrder:string = '',filtType:number = 0, filtCustomer:number = 0, filtEngineer:number = 0): Observable<any> {

        this.finalUrl = `${this._url}/${pageIndex}/${pageSize}`;

        if(shortBy !=='' && shortOrder !==''){
            this.finalUrl = `${this.finalUrl}/${shortBy}/${shortOrder}`;
        }else{
            this.finalUrl = `${this.finalUrl}/date/desc`;
        }

        if(filtType>0 || filtCustomer>0 || filtEngineer >0){
            this.finalUrl += '?type='+filtType+'&customer='+filtCustomer+'&engineer='+filtEngineer;
        }

        return this.http.get<Visit[]>(this.finalUrl);
    }

    // Returns a specific Visit
    get(id: number): Observable<Visit> {
        return this.http.get<Visit>(`${this._url}/${id}`);
    }

    // Adds a Visit
    add(visit: Visit): Observable<any> {
        return this.http.post(this._url, visit );
    }

    // Updates a Visit
    update(id: number, visit: Visit): Observable<any> {
        return this.http.put(`${this._url}/${id}`, visit);
    }

    // Deletes a Visit
    delete(id: number): Observable<any> {
        return this.http.delete(`${this._url}/${id}`);
    }
}
