import { GroupsService } from './../../_services/groups/groups.service';
import { Component, OnInit, Inject } from '@angular/core';
import { Group } from '../../_models/group.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NewCustomerComponent } from '../../customers/customer-new/new-customer.component';
import { AlertService } from '../../_services';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-groups-new',
  templateUrl: './groups-new.component.html',
  styleUrls: ['./groups-new.component.css']
})
export class GroupsNewComponent {

  // Defininig the form
  form = new FormGroup({
    name: new FormControl('', [
      Validators.required,
    ]),
    color: new FormControl('', [
      Validators.nullValidator
    ]),
    comments: new FormControl('', [
      Validators.nullValidator
    ]),
  });

  // Used to disable the submit button on click
  submitting = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<NewCustomerComponent>,
    private service: GroupsService,
    private alertService: AlertService) { }

  onSubmit(model: Group, isValid: boolean) {

    if (!isValid) {
      this.alertService.error('Please fill the required fields');
      return;
    }

    this.submitting = true;

    this.service.add(model).subscribe(res => {
      this.alertService.success('Group Added');
      this.data.dataSource.load();
      this.dialogRef.close();
    },
    error => {
      this.alertService.error(error);
      this.submitting = false;
    });
  }

  // Close this dialog window
  onCancelClick() {
    this.dialogRef.close();
  }

}
