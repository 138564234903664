import { UserService } from './../../_services/user.service';
import { UsersDataSource } from './users.datasource';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BreakpointState, Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material';
import { UsersNewComponent } from '../users-new/users-new.component';
import { UsersEditComponent } from '../users-edit/users-edit.component';
import { AuthenticationService } from '../../_services';

@Component({
  selector: 'app-users-view',
  templateUrl: './users-view.component.html',
  styleUrls: ['./users-view.component.css']
})
export class UsersViewComponent implements OnInit {

  isAdmin: boolean;
  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);
  displayedColumns: string[] = ['id', 'email', 'name', 'group', 'active'];
  dataSource: UsersDataSource;


  constructor(
    private breakpointObserver: BreakpointObserver,
    private service: UserService,
    private authService: AuthenticationService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.isAdmin = this.authService.idAdmin();
    this.dataSource = new UsersDataSource(this.service);
    this.dataSource.load();
  }

  // Open the Edit Form dialog
  onRowEditClick(row) {
    const dialogRef = this.dialog.open(UsersEditComponent, {
      width: '700px',
      height: '620px',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: {
        id: row.id,
        dataSource: this.dataSource
      }
    });

    const smallDialogSubscription = this.isExtraSmall.subscribe(result => {
      if (result.matches) {
        dialogRef.updateSize('100%', '100%');
      } else {
        dialogRef.updateSize('700px', '620px');
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      smallDialogSubscription.unsubscribe();
    });
  }

  // Open the Add Form dialog
  onAddClick() {
    const dialogRef = this.dialog.open(UsersNewComponent, {
      width: '700px',
      height: '620px',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: {
        dataSource: this.dataSource
      }
    });

    const smallDialogSubscription = this.isExtraSmall.subscribe(result => {
      if (result.matches) {
        dialogRef.updateSize('100%', '100%');
      } else {
        dialogRef.updateSize('700px', '620px');
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      smallDialogSubscription.unsubscribe();
    });
  }
}
