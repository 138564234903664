import { UserService } from './../../_services/user.service';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { User } from '../../_models/user';

export class UsersDataSource implements DataSource<User> {

    private visitSubject = new BehaviorSubject<User[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();

    constructor(private service: UserService) { }

    connect(collectionViewer: CollectionViewer): Observable<User[]> {
        return this.visitSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.visitSubject.complete();
        this.loadingSubject.complete();
    }

    load() {
        this.loadingSubject.next(true);

        this.service.getAll()
        .pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe (
            users => {
                this.visitSubject.next(users);
            }
        );
    }
}
