import { repeat } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ScheduledTask } from '../_models/scheduledTask.model';
import { until } from 'protractor';

@Injectable()
export class RRuleParserService {

    constructor() { }

    toRRuleString(task: any): string {
        if (!task.repeat) {
            return '';
        }
        const freq = `FREQ=${task.repeatDateType}`;
        const interval = `INTERVAL=${task.repeatNum}`;
        // tslint:disable-next-line:no-shadowed-variable
        let until: string;
        let byDay: string;

        if (task.endsType === 'N') {
            until = `UNTIL=${this.getMaxDate()}`;
        } else if (task.endsType === 'O') {
            until = `UNTIL=${this.getFormatedDate(task.untilDate)}`;
        } else if (task.endsType === 'A') {
            until = `COUNT=${task.occurrences}`;
        } else {
            return '';
        }

        if (task.repeatDateType === 'WEEKLY') {
            byDay = `BYDAY=`;
        }

        return `${freq};${interval};${until}`;
    }

    private getFormatedDate(date): string {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();

        if (month.length < 2) { month = '0' + month; }
        if (day.length < 2) { day = '0' + day; }

        return `${year}${month}${day}T000000Z`;
    }

    private getMaxDate(): string {
        return '99991231T000000Z';
    }
}
