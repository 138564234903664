import { AlertService } from './../../_services/alert.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TasksService } from '../../_services/tasks.service';
import { Task } from '../../_models/task';
import { FormControl, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-tasks-new',
  templateUrl: './tasks-new.component.html',
  styleUrls: ['./tasks-new.component.css']
})
export class TasksNewComponent {

  // Used to disable the submit button on click
  submitting = false;

  // Define the Form
  tasksform = new FormGroup({
    title: new FormControl('', [
      Validators.required,
    ]),
    type: new FormControl('', [
      Validators.required,
    ]),
    taskType: new FormControl('', [
      Validators.required
    ])
  });

  // Define the Types
  types = [
    {id: 1, name: 'Checkbox'},
    {id: 2, name: 'Numeric'},
    {id: 3, name: 'Text Box'},
    {id: 4, name: 'Text Area'}
  ];

  taskTypes = [
    {id: 1, name: 'Visit'},
    {id: 2, name: 'Remote'},
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TasksNewComponent>,
    private service: TasksService,
    private alertService: AlertService,
  ) { }

  // Submits Data to the back end
  onSubmit(model: Task, isValid: boolean) {

    if (!isValid) {
      this.alertService.error('Please fill a required fields');
      return;
    }

    this.submitting = true;

    this.service.add(model).subscribe(res => {
      this.alertService.success('Task Added');
      this.data.dataSource.load();
      this.dialogRef.close();
    },
    error => {
      this.alertService.error(error.statusText);
      this.submitting = false;
    });
  }

  // Closes the form
  onCancelClick() {
    this.dialogRef.close();
  }
}
