import { EmailGroupsService } from 'src/app/_services/emailgroup.service';
import { ShowFirstMail } from './_helpers/firstmail.pipe';
import { TasksService } from './_services/tasks.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { MatGridListModule } from '@angular/material/grid-list';

import { AppComponent } from './app.component';
import { routing } from './app.routing';

import { AlertComponent } from './_directives/alert.component';
import { AuthGuard } from './_guards/auth.guard';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { AlertService, AuthenticationService, UserService } from './_services/index';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { CustomersComponent } from './customers/customers-view/customers.component';
import { HeaderComponent } from './header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatInputModule,
    MatCardModule,
    MatDialogModule,
    MatSidenavModule,
    MatSelectModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatIconModule,
    MatToolbarModule,
    MatListModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatRadioGroup,
    MatChipsModule,
    MatSlideToggleModule,
    MatSortModule,
    MatRadioModule,
    MatNativeDateModule
    } from '@angular/material';
import { CustomerService } from './_services/customers.service';
import { AccordionModule } from 'primeng/accordion';     // accordion and accordion tab
import { MenuItem } from 'primeng/api';                 // api
import { PasswordModule } from 'primeng/password';
import { NewCustomerComponent } from './customers/customer-new/new-customer.component';
import { CustomerEditComponent } from './customers/customer-edit/customer-edit.component';
import { CustomerGroupsComponent } from './groups/groups-view/customer-groups.component';
import { GroupsNewComponent } from './groups/groups-new/groups-new.component';
import { GroupsEditComponent } from './groups/groups-edit/groups-edit.component';
import { DeleteQuestionDialogComponent } from './delete-question-dialog/delete-question-dialog.component';
import { TasksViewComponent } from './tasks/tasks-view/tasks-view.component';
import { TasksNewComponent } from './tasks/tasks-new/tasks-new.component';
import { TasksEditComponent } from './tasks/tasks-edit/tasks-edit.component';
import { UsersViewComponent } from './users/users-view/users-view.component';
import { UsersEditComponent } from './users/users-edit/users-edit.component';
import { UsersNewComponent } from './users/users-new/users-new.component';
import { VisitsViewComponent } from './visits/visits-view/visits-view.component';
import { VisitsEditComponent } from './visits/visits-edit/visits-edit.component';
import { VisitsNewComponent } from './visits/visits-new/visits-new.component';
import { UserIconDialogComponent } from './header/user-icon-dialog/user-icon-dialog.component';
import { AdminComponent } from './admin/admin.component';
import { AdminService } from './_services/admin.service';
import { ImageuploadComponent } from './users/imageupload/imageupload.component';
import { ImageCropperModule} from 'ng2-img-cropper/index';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ScheduledVisitsNewComponent } from './scheduledVisits/scheduled-visits-new/scheduled-visits-new.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RRuleParserService } from './_services/rruleParser.service';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        routing,
        BrowserAnimationsModule,
        MatTableModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatInputModule,
        MatCardModule,
        HttpModule,
        AccordionModule,
        PasswordModule,
        MatDialogModule,
        MatSidenavModule,
        MatSelectModule,
        MatProgressBarModule,
        MatSnackBarModule,
        MatIconModule,
        MatToolbarModule,
        MatListModule,
        CommonModule,
        MatAutocompleteModule,
        MatMenuModule,
        ReactiveFormsModule,
        MatTooltipModule,
        MatPaginatorModule,
        ImageCropperModule,
        MatGridListModule,
        MatChipsModule,
        MatSortModule,
        MatSlideToggleModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        NgbModalModule,
        MatRadioModule,
        MatDatepickerModule,
        MatNativeDateModule
    ],
    declarations: [
        AppComponent,
        AlertComponent,
        HomeComponent,
        LoginComponent,
        RegisterComponent,
        CustomersComponent,
        CustomerGroupsComponent,
        HeaderComponent,
        NewCustomerComponent,
        CustomerEditComponent,
        DeleteQuestionDialogComponent,
        GroupsNewComponent,
        GroupsEditComponent,
        TasksViewComponent,
        TasksNewComponent,
        TasksEditComponent,
        UsersViewComponent,
        UsersEditComponent,
        UsersNewComponent,
        VisitsViewComponent,
        VisitsEditComponent,
        VisitsNewComponent,
        UserIconDialogComponent,
        ShowFirstMail,
        AdminComponent,
        ImageuploadComponent,
        ScheduledVisitsNewComponent
    ],
    entryComponents: [
        DeleteQuestionDialogComponent,
        NewCustomerComponent,
        CustomerEditComponent,
        GroupsNewComponent,
        GroupsEditComponent,
        TasksNewComponent,
        TasksEditComponent,
        UsersEditComponent,
        UsersNewComponent,
        VisitsEditComponent,
        VisitsNewComponent,
        UserIconDialogComponent,
        ImageuploadComponent,
        ScheduledVisitsNewComponent
    ],
    providers: [
        AuthGuard,
        AlertService,
        AuthenticationService,
        UserService,
        CustomerService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        TasksService,
        AdminService,
        EmailGroupsService,
        RRuleParserService
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
