import { GroupsDataSource } from './groups.datasource';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BreakpointState, BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material';
import { GroupsService } from '../../_services/groups/groups.service';
import { GroupsNewComponent } from '../groups-new/groups-new.component';
import { DeleteQuestionDialogComponent } from '../../delete-question-dialog/delete-question-dialog.component';
import { GroupsEditComponent } from '../groups-edit/groups-edit.component';

@Component({
  selector: 'app-customer-groups',
  templateUrl: './customer-groups.component.html',
  styleUrls: ['./customer-groups.component.css']
})
export class CustomerGroupsComponent implements OnInit {

  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);
  displayedColumns: string[] = ['name', 'color', 'comments'];
  dataSource: GroupsDataSource;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private service: GroupsService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.dataSource = new GroupsDataSource(this.service);
    this.dataSource.load();
  }

  // Open the Add Form Dialog
  onAddClick() {
    const dialogRef = this.dialog.open(GroupsNewComponent, {
      width: '700px',
      height: '400px',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: {dataSource: this.dataSource}
    });

    const smallDialogSubscription = this.isExtraSmall.subscribe(result => {
      if (result.matches) {
        dialogRef.updateSize('100%', '100%');
      } else {
        dialogRef.updateSize('700px', '400px');
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      smallDialogSubscription.unsubscribe();
    });
  }

  // Open the Edit Form dialog
  onRowEditClick(row) {
    const dialogRef = this.dialog.open(GroupsEditComponent, {
      width: '700px',
      height: '400px',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: {
        id: row.id,
        dataSource: this.dataSource
      }
    });

    const smallDialogSubscription = this.isExtraSmall.subscribe(result => {
      if (result.matches) {
        dialogRef.updateSize('100%', '100%');
      } else {
        dialogRef.updateSize('700px', '400px');
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      smallDialogSubscription.unsubscribe();
    });
  }
}
