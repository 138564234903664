import { AuthenticationService } from './../../_services/authentication.service';
import { Component, OnInit, Inject } from '@angular/core';
import { User } from '../../_models/user';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NewCustomerComponent } from '../../customers/customer-new/new-customer.component';
import { AlertService, UserService } from '../../_services';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-users-new',
  templateUrl: './users-new.component.html',
  styleUrls: ['./users-new.component.css']
})
export class UsersNewComponent implements OnInit {

// Defininig the form
form = new FormGroup({
  firstname: new FormControl('', [
    Validators.required,
  ]),
  lastname: new FormControl('', [
    Validators.required
  ]),
  groupId: new FormControl('', [
    Validators.required
  ]),
  email: new FormControl('', [
    Validators.required,
  ]),
  password: new FormControl('', [
    Validators.required
  ]),
  active: new FormControl('true', [
    Validators.nullValidator
  ]),
  userImageUrl: new FormControl('', [
    Validators.nullValidator
  ])
});

  // Used to disable the submit button on click
  submitting = false;

  // Used to determine if a user can create a new User (only Admins can create new users)
  isAdmin: boolean;
  userGroups = [
    {id: 0, name: 'Admin'},
    {id: 1, name: 'User'},
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<NewCustomerComponent>,
    private service: UserService,
    private authService: AuthenticationService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.isAdmin = this.authService.idAdmin();
  }

  // Registers the User
  onSubmit(model: User, isValid: boolean) {

    if (!isValid) {
      this.alertService.error('Please fill the required fields');
      return;
    }

    this.submitting = true;

    this.service.create(model).subscribe(res => {
      this.alertService.success('User Added');
      this.data.dataSource.load();
      this.dialogRef.close();
    },
    error => {
      this.alertService.error(error._body);
      this.submitting = false;
    });
  }

  // Close this window
  onCancelClick() {
    this.dialogRef.close();
  }

}
