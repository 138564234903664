import { TasksEditComponent } from './../tasks-edit/tasks-edit.component';
import { TasksNewComponent } from './../tasks-new/tasks-new.component';
import { Component, OnInit } from '@angular/core';
import { TasksDataSource } from './tasks.datasource';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { TasksService } from '../../_services/tasks.service';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { DeleteQuestionDialogComponent } from '../../delete-question-dialog/delete-question-dialog.component';

@Component({
  selector: 'app-tasks-view',
  templateUrl: './tasks-view.component.html',
  styleUrls: ['./tasks-view.component.css']
})
export class TasksViewComponent implements OnInit {

  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);
  displayedColumns: string[] = ['id', 'title', 'type', 'taskType'];
  dataSource: TasksDataSource;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private service: TasksService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.dataSource = new TasksDataSource(this.service);
    this.dataSource.load();
  }

  // onRowDeleteClick(row) {
  //   console.log(row);
  //   event.stopPropagation();
  //   const dialogRef = this.dialog.open(DeleteQuestionDialogComponent, {
  //     width: '250px',
  //     data: {
  //       id: row.id,
  //       dataSource: this.dataSource,
  //       service: this.service
  //     }
  //   });
  // }

  // Open the Add Form Dialog
  onAddClick() {
    const dialogRef = this.dialog.open(TasksNewComponent, {
      width: '700px',
      height: '400px',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: {
        dataSource: this.dataSource
      }
    });

    const smallDialogSubscription = this.isExtraSmall.subscribe(result => {
      if (result.matches) {
        dialogRef.updateSize('100%', '100%');
      } else {
        dialogRef.updateSize('700px', '400px');
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      smallDialogSubscription.unsubscribe();
    });
  }

  // Open the Edit Form dialog
  onRowEditClick(row) {
    const dialogRef = this.dialog.open(TasksEditComponent, {
      width: '700px',
      height: '400px',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: {
        id: row.id,
        dataSource: this.dataSource
      }
    });

    const smallDialogSubscription = this.isExtraSmall.subscribe(result => {
      if (result.matches) {
        dialogRef.updateSize('100%', '100%');
      } else {
        dialogRef.updateSize('700px', '400px');
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      smallDialogSubscription.unsubscribe();
    });
  }
}
