import { catchError, finalize } from 'rxjs/operators';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { GroupsService } from '../../_services/groups/groups.service';
import { Group } from '../../_models/group.model';

export class GroupsDataSource implements DataSource<Group> {

    private groupsSubject = new BehaviorSubject<Group[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();

    constructor(private service: GroupsService) { }

    connect(collectionViewer: CollectionViewer): Observable<Group[]> {
        return this.groupsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.groupsSubject.complete();
        this.loadingSubject.complete();
    }

    load() {
        this.loadingSubject.next(true);

        this.service.getAll()
        .pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe (
            groups => this.groupsSubject.next(groups)
        );
    }
}
