// import { VisitsService } from './../../_services/visits.service';
import { DataSource } from '@angular/cdk/table';
// import { Visit } from '../../_models/visit';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import {
    CalendarEvent
   } from 'angular-calendar';

export class DayEventsDataSource implements DataSource<CalendarEvent> {

    dayEvents: CalendarEvent[] = [];
    private eventsSubject = new BehaviorSubject<any>({

    });
    // private loadingSubject = new BehaviorSubject<boolean>(false);
    // public loading$ = this.loadingSubject.asObservable();
    // public totalVisits = new BehaviorSubject<number>(0);

    // constructor(private service: VisitsService) { }
    connect(collectionViewer: CollectionViewer): Observable<any> {
        return this.eventsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.eventsSubject.complete();
    }

    load(events: CalendarEvent[]) {
        this.eventsSubject.next(events);
        // console.log(this.eventsSubject);
    }
}
