import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { AuthenticationService } from '../../_services';
import { Router } from '@angular/router';
import { UsersEditComponent } from '../../users/users-edit/users-edit.component';
import { Observable } from 'rxjs';
import { BreakpointState, Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { ImageuploadComponent } from 'src/app/users/imageupload/imageupload.component';

@Component({
  selector: 'app-user-icon-dialog',
  templateUrl: './user-icon-dialog.component.html',
  styleUrls: ['./user-icon-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UserIconDialogComponent implements OnInit {

  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);
  user;

  constructor(
    private breakpointObserver: BreakpointObserver,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserIconDialogComponent>,
    private auth: AuthenticationService,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.user = this.auth.getCurrentUser();
  }

  onClose() {
    this.dialogRef.close();
  }

  logout() {
    this.onClose();

    // remove local storage object
    this.auth.logout();
    this.router.navigate(['/login']);
    // window.location.reload();
  }

  // Open the Edit Form dialog
  openProfile() {
    this.onClose();
    const dialogRef = this.dialog.open(UsersEditComponent, {
      width: '700px',
      height: '700px',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: {
        id: this.auth.getUserId(),
      }
    });

    const smallDialogSubscription = this.isExtraSmall.subscribe(result => {
      if (result.matches) {
        dialogRef.updateSize('100%', '100%');
      } else {
        dialogRef.updateSize('700px', '700px');
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      smallDialogSubscription.unsubscribe();
    });
  }

  // Open image upload dialog
  openUploadDialog() {
    const dialogRef = this.dialog.open(ImageuploadComponent, {
      width: '700px',
      height: '700px',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: {
        id: this.auth.getUserId(),
      }
    });
  }

}
