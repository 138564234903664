import { TasksService } from './../../_services/tasks.service';
import { catchError, finalize } from 'rxjs/operators';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { Task } from '../../_models/task';

export class TasksDataSource implements DataSource<Task> {

    private tasksSubject = new BehaviorSubject<Task[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();

    constructor(private service: TasksService) { }

    connect(collectionViewer: CollectionViewer): Observable<Task[]> {
        return this.tasksSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.tasksSubject.complete();
        this.loadingSubject.complete();
    }

    load() {
        this.loadingSubject.next(true);

        this.service.getAll()
        .pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe (
            tasks => this.tasksSubject.next(tasks)
        );
    }
}
