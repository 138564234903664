import { Group } from './../../_models/group.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as globals from '../../globals';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {
  private _url = `${globals.API_URL}/customergroups`;

  constructor(private http: HttpClient) { }

  // Returns all Customer Groups
  getAll(): Observable<Group[]> {
    return this.http.get<Group[]>(this._url);
  }

  // Returns a specific Customer Group
  get(id: number): Observable<Group> {
    return this.http.get<Group>(`${this._url}/${id}`);
  }

  // Adds a Customer Group
  add(group: Group): Observable<any> {
    return this.http.post(this._url, group );
  }

  // Updates a Customer Group
  update(id: number, group: Group): Observable<any> {
      return this.http.put(`${this._url}/${id}`, group);
  }

  // Deletes a Customer Group
  delete(id: number): Observable<any> {
      return this.http.delete(`${this._url}/${id}`);
  }

}
