import * as globals from './../globals';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/map';

@Injectable()
export class AuthenticationService {

    private _isUserLogged = new BehaviorSubject<boolean>(false);
    private _url = globals.API_URL;
    private _storageItemName = 'currentUser';

    get isLoggedIn(): Observable<boolean> {
        return this._isUserLogged.asObservable();

    }

    constructor(private http: HttpClient) {
        const currentUser = localStorage.getItem(this._storageItemName);
        if (currentUser) {
            this._isUserLogged.next(true);
        } else {
            this._isUserLogged.next(false);
        }
    }

    login(email: string, password: string) {
        return this.http.post<any>(this._url + '/users/authenticate', {email: email, password: password})
        .pipe(
            map(user => {
                if (user && user.token) {
                    const userJson = JSON.stringify(user);
                    localStorage.setItem(this._storageItemName, userJson);
                    this._isUserLogged.next(true);
                }
            })
        );
    }

    idAdmin(): boolean {
        const currentUser = JSON.parse(localStorage.getItem(this._storageItemName));
        if (currentUser.groupId === 0) {
            return true;
        } else {
            return false;
        }
    }

    getUserId(): number {
        const currentUser = JSON.parse(localStorage.getItem(this._storageItemName));
        return currentUser.id;
    }

    getCurrentUser() {
        const currentUser = JSON.parse(localStorage.getItem(this._storageItemName));
        return currentUser;
    }

    // Updates the image when a user uploads an image
    setImage(imageUrl: string) {
        const user = this.getCurrentUser();
        user.userImageUrl = imageUrl;
        const userJson = JSON.stringify(user);
        localStorage.setItem(this._storageItemName, userJson);
    }

    logout() {
        // remove local storage object
        this._isUserLogged.next(false);
        localStorage.removeItem(this._storageItemName);
    }
}
