import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { User } from '../_models/user';
import * as globals from './../globals';

@Injectable()
export class UserService {
    private _url = `${globals.API_URL}/users`;

    constructor(private http: Http) {}

    getAll() {
        return this.http.get(this._url, this.jwt()).map((response: Response) => response.json());
    }

    get(_id: string) {
        return this.http.get(this._url + '/' + _id, this.jwt()).map((response: Response) => response.json());
    }

    create(user: User) {
        return this.http.post(this._url + '/register', user, this.jwt());
    }

    update(user: User) {
        return this.http.put(this._url + '/' + user.id, user, this.jwt());
    }

    delete(_id: string) {
        return this.http.delete(this._url + '/' + _id, this.jwt());
    }

    private jwt() {
        // create authorization header with jwt token
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.token) {
            const headers = new Headers({ 'Authorization': 'Bearer ' + currentUser.token });
            return new RequestOptions({ headers: headers });
        }
    }
}
