import { TasksService } from './../../_services/tasks.service';
import { Component, OnInit, Inject } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { AlertService } from '../../_services';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { catchError, finalize } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Task } from '../../_models/task';
import { DeleteQuestionDialogComponent } from '../../delete-question-dialog/delete-question-dialog.component';

@Component({
  selector: 'app-tasks-edit',
  templateUrl: './tasks-edit.component.html',
  styleUrls: ['./tasks-edit.component.css']
})
export class TasksEditComponent implements OnInit {

  // Define the Form
  tasksform = new FormGroup({
    id: new FormControl('', [
      Validators.nullValidator,
    ]),
    title: new FormControl('', [
      Validators.required,
    ]),
    type: new FormControl('', [
      Validators.required,
    ]),
    taskType: new FormControl('', [
      Validators.required
    ])
  });

  // Used to disable the submit button on click
  submitting = false;

  // Define the Types
  types = [
    {id: 1, name: 'Checkbox'},
    {id: 2, name: 'Numeric'},
    {id: 3, name: 'Text Box'},
    {id: 4, name: 'Text Area'}
  ];

  taskTypes = [
    {id: 1, name: 'Visit'},
    {id: 2, name: 'Remote'},
  ];

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TasksEditComponent>,
    private service: TasksService,
    private alertService: AlertService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loadTask();
  }

  // Loads the task from the back end
  loadTask() {
    this.loadingSubject.next(true);

    this.service.get(this.data.id)
    .pipe(
        catchError(() => of()),
        finalize(() => this.loadingSubject.next(false))
    )
    .subscribe(
        task => {
          this.tasksform.setValue(task);
    });
  }

  // Submits Data to the back end
  onSubmit(model: Task, isValid: boolean) {

    if (!isValid) {
      this.alertService.error('Please fill a required fields');
      return;
    }

    this.submitting = true;

    this.service.update(model.id, model).subscribe(res => {
      this.alertService.success('Task Updated');
      this.data.dataSource.load();
      this.dialogRef.close();
    },
    error => {
      this.alertService.error(error.statusText);
      this.submitting = false;
    });
  }

  // Closes the form
  onCancelClick() {
    this.dialogRef.close();
  }

  onDeleteClick(model: Task) {
    // Open the question dialog
    const dialogRef = this.dialog.open(DeleteQuestionDialogComponent, {
      width: '250px',
      data: {
        id: model.id,
        dataSource: this.data.dataSource,
        service: this.service,
        dialog: this.dialogRef
      }
    });
  }
}
