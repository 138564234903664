import { VisitsService } from './../../_services/visits.service';
import { DataSource } from '@angular/cdk/table';
import { Visit } from '../../_models/visit';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

export class VisitsDataSource implements DataSource<Visit> {

    private visitSubject = new BehaviorSubject<any>({
        total: -1,
        data: null
    });
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    public totalVisits = new BehaviorSubject<number>(0);

    constructor(private service: VisitsService) { }

    connect(collectionViewer: CollectionViewer): Observable<any> {
        return this.visitSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.visitSubject.complete();
        this.loadingSubject.complete();
    }

    load(pageIndex: number, pageSize: number,shortBy: string = '',shortOrder:string = '',filtType:number = 0, filtCustomer:number = 0, filtEngineer:number = 0) {
        this.loadingSubject.next(true);

        this.service.getAll(pageIndex, pageSize,shortBy,shortOrder,filtType,filtCustomer,filtEngineer)
        .pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe (
            visits => {
               this.visitSubject.next(visits.data);
               this.totalVisits.next(visits.total);
            });
    }
}
