import { UserService } from './../_services/user.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../_services';

@Injectable()
export class AuthGuard implements CanActivate {

    checkedWithServer = false;

    constructor(private router: Router, private auth: AuthenticationService, private userService: UserService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isAuthorized = this.getAuthorizedUser();
        if (!isAuthorized) {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        }
        return isAuthorized;
    }

    private getAuthorizedUser(): boolean {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser) {
            console.log(currentUser);
            // We call the server to check if the current token is still valid
            if (!this.checkedWithServer) {
                this.userService.get(currentUser.id).subscribe(res => {
                    this.checkedWithServer = true;
                },
                error => {
                    if (error.status === 401) {
                        localStorage.removeItem('currentUser');
                        return false;
                    }
                });
            }

            return true;
        } else {
            return false;
        }
    }
}
