import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
   templateUrl: './delete-question-dialog.component.html'
})
export class DeleteQuestionDialogComponent {

  constructor(
    // private service: GroupsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DeleteQuestionDialogComponent>
    ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    this.data.service.delete(this.data.id).subscribe(
      () => {
      this.data.dataSource.load();
      if (this.data.dialog) {
        this.data.dialog.close();
      }
      this.dialogRef.close();
    });
  }

}
