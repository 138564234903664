import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Customer } from '../../_models/customer';
import { CustomerService } from '../../_services/customers.service';
import { AlertService } from '../../_services';
import { CustomerTasksDataSource } from './tasks.datasource';
import { TasksService } from '../../_services/tasks.service';
import { CustomerTask } from '../../_models/customerTask';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ValidateEmails } from '../../_helpers/EmailsValidator';
import { GroupsService } from 'src/app/_services/groups/groups.service';
import { EmailGroupsService } from 'src/app/_services/emailgroup.service';

@Component({
  selector: 'app-new-customer',
  templateUrl: './new-customer.component.html',
  styleUrls: ['./new-customer.component.css']
})
export class NewCustomerComponent implements OnInit {

  // Defininig the form
  form = new FormGroup({
    name: new FormControl('', [
      Validators.required,
    ]),
    groupId: new FormControl('', [
      Validators.nullValidator
    ]),
    address: new FormControl('', [
      Validators.nullValidator
    ]),
    emails: new FormControl('', [
      ValidateEmails
    ]),
    emailGroupId: new FormControl(1, [
      Validators.nullValidator
    ]),
    contractUsers: new FormControl('', [
      Validators.required,
    ]),
    active: new FormControl('true', [
      Validators.nullValidator
    ]),
    visits: new FormControl('0', [
      Validators.nullValidator
    ]),
  });

  // Used to disable the submit button on click
  submitting = false;

  customerGroups = [];

  emailGroups = [];

  tasksDataSource: CustomerTasksDataSource;
  displayedColumns: string[] = ['select', 'title', 'type', 'taskType'];


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<NewCustomerComponent>,
    private service: CustomerService,
    private customerGroupService: GroupsService,
    private tasksService: TasksService,
    private emailGroupsService: EmailGroupsService,
    private alertService: AlertService) {}

  ngOnInit(): void {
    this.loadCustomerGroups();
    this.loadCustomerTasks();
    this.loadEmailGroups();
  }

  // Loads all of the tasks so that the user can choose the ones regarding this customer
  loadCustomerTasks() {
    this.tasksDataSource = new CustomerTasksDataSource(this.tasksService);
    this.tasksDataSource.load(true);
  }

  // Loads the customer groups to the dropdown
  loadCustomerGroups() {
    this.customerGroupService.getAll().subscribe((res) => this.customerGroups = res);
  }

  loadEmailGroups() {
    this.emailGroupsService.getAll().subscribe((res) => this.emailGroups = res);
  }

  // Submits Data to the back end
  onSubmit(model: Customer, isValid: boolean) {

    if (!isValid) {
      this.alertService.error('Please fill the required fields');
      return;
    }

    this.submitting = true;

    model.customerTasks = [];
    // Add only assigned Tasks to Customer model
    this.tasksDataSource.selection.selected.forEach(
      i => model.customerTasks.push(new CustomerTask(i.id))
    );

    this.service.add(model).subscribe(res => {
      this.alertService.success('Customer Added');
      // this.data.dataSource.load();
      // this.dialogRef.close();
    },
    error => {
      this.alertService.error(error.errorMessage);
      this.submitting = false;
    });
  }

  // Closes the form
  onCancelClick() {
    this.dialogRef.close();
  }
}
