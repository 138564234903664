import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Customer } from '../_models/customer';
import * as globals from './../globals';

@Injectable()
export class CustomerService {
    private _url = `${globals.API_URL}/customers`;

    constructor(private http: HttpClient) { }

    // Returns all Customers
    getAll(params = ''): Observable<Customer[]> {
        return this.http.get<Customer[]>(`${this._url}?${params}`);
    }

    // Returns a specific Customer
    get(id: number): Observable<Customer> {
        return this.http.get<Customer>(`${this._url}/${id}`);
    }

    // Adds a Customer
    add(customer: Customer): Observable<any> {
        return this.http.post(this._url, customer);
    }

    // Updates a Customer
    update(id: number, customer: Customer): Observable<any> {
        return this.http.put(`${this._url}/${id}`, customer);
    }

    // Deletes a Customer
    delete(id: number): Observable<any> {
        return this.http.delete(`${this._url}/${id}`);
    }
}
