import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as globals from './../globals';
import { Admin } from '../_models/admin';

@Injectable()
export class AdminService {
    private _url = `${globals.API_URL}/admin`;

    constructor(private http: HttpClient) { }

    // Returns the admin object
    get(): Observable<Admin> {
        return this.http.get<Admin>(`${this._url}`);
    }

    // Updates the admin object
    update(admin: Admin): Observable<any> {
        return this.http.put(`${this._url}`, admin);
    }
}
