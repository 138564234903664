import { Component, OnInit, ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';
import { User } from '../_models/user';
import { UserService } from '../_services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours
} from 'date-fns';
import { Subject, Observable } from 'rxjs';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView
 } from 'angular-calendar';
import { Action } from 'rxjs/internal/scheduler/Action';
import { DayEventsDataSource } from './dayEvents.datasource';
import { BreakpointState, Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { ScheduledVisitsNewComponent } from '../scheduledVisits/scheduled-visits-new/scheduled-visits-new.component';
import { MatDialog } from '@angular/material';

 const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  }
};

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent {
  @ViewChild('modalContent')
  modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  selectedDate: Date = new Date();
  dataSource = new DayEventsDataSource();

  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);
  displayedColumns: string[] = ['date', 'duration', 'customer', 'engineer', 'color'];

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  currentUser: User;
  users: User[] = [];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private modal: NgbModal,
    public dialog: MatDialog,
    private userService: UserService) {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      onClick: ({event}: {event: CalendarEvent}): void => {
        this.handleEvent('Edited', event);
      }
    },
    {
      label: '<i class="fa fa-fw fa-times"></i>',
      onClick: ({event}: {event: CalendarEvent}): void => {
        this.allEvents = this.allEvents.filter(iEvent => iEvent !== event);
        this.handleEvent('Deleted', event);
      }
    }
  ];

  refresh: Subject<any> = new Subject();

  dayEvents: CalendarEvent[] = [

  ];

  allEvents: CalendarEvent[] = [
    {
      start: subDays(startOfDay(new Date()), 1),
      end: addDays(new Date(), 1),
      title: 'A 3 day Event',
      color: colors.red,
      actions: this.actions,
      allDay: true,
      resizable: {
        beforeStart: true,
        afterEnd: true
      },
      // draggable: true
    },
    {
      start: startOfDay(new Date()),
      title: 'An event with no end date',
      color: colors.yellow,
      actions: this.actions
    },
    {
      start: subDays(endOfMonth(new Date()), 3),
      end: addDays(endOfMonth(new Date()), 3),
      title: 'A long event that spans 2 months',
      color: colors.blue,
      allDay: true
    },
    {
      start: addHours(startOfDay(new Date()), 2),
      end: new Date(),
      title: 'A draggable and resizable event',
      color: colors.yellow,
      actions: this.actions,
      resizable: {
        beforeStart: true,
        afterEnd: true
      },
      // draggable: true
    }
  ];

  activeDayIsOpen = false;


  dayClicked({date, events}: {date: Date; events: CalendarEvent[] }): void {
    this.viewDate = date;
    this.dayEvents = events;
    this.selectedDate = date;
    this.dataSource.load(events);
    // if (isSameMonth(date, this.viewDate)) {
    //   this.viewDate = date;
    //   if (
    //     (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
    //     events.length === 0
    //   ) {
    //     this.activeDayIsOpen = false;
    //   } else {
    //     this.activeDayIsOpen = true;
    //   }
    // }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.handleEvent('Dropped of resized', event);
    this.refresh.next();
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = {event, action };
    this.modal.open(this.modalContent, {size: 'lg'});
  }

  addEvent(): void {
    const dialogRef = this.dialog.open(ScheduledVisitsNewComponent, {
      width: '700px',
      height: '520px',
      maxWidth: '100vw',
      maxHeight: '100vh',
      disableClose: false,
      data: {
        dataSource: this.dataSource
      }
    });

    const smallDialogSubscription = this.isExtraSmall.subscribe(result => {
      if (result.matches) {
        dialogRef.updateSize('100%', '100%');
      } else {
        dialogRef.updateSize('700px', '520px');
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      smallDialogSubscription.unsubscribe();
    });

    // this.allEvents.push({
    //   title: 'New event',
    //   start: startOfDay(new Date()),
    //   end: endOfDay(new Date()),
    //   color: colors.red,
    //   draggable: true,
    //   resizable: {
    //     beforeStart: true,
    //     afterEnd: true
    //   }
    // });
    // this.refresh.next();
  }
}
