import { CustomerTask } from './../../_models/customerTask';
import { DataSource } from '@angular/cdk/collections';
import { Customer } from './../../_models/customer';
import { UserService } from './../../_services/user.service';
import { User } from './../../_models/user';
import { CustomerService } from './../../_services/customers.service';
import { Visit } from './../../_models/visit';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { VisitsEditComponent } from '../visits-edit/visits-edit.component';
import { VisitsService } from '../../_services/visits.service';
import { AlertService, AuthenticationService } from '../../_services';
import { Observable } from 'rxjs';
import { CompletedTask } from '../../_models/completedTask';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Group } from '../../_models/group.model';
import { formArrayNameProvider } from '@angular/forms/src/directives/reactive_directives/form_group_name';

@Component({
  selector: 'app-visits-new',
  templateUrl: './visits-new.component.html',
  styleUrls: ['./visits-new.component.css']
})
export class VisitsNewComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<VisitsEditComponent>,
    private service: VisitsService,
    private customerService: CustomerService,
    private usersService: UserService,
    private authService: AuthenticationService,
    private alertService: AlertService,
    private fb: FormBuilder
  ) { }

  form = this.fb.group({
    customerId: ['', [Validators.required]],
    engineerId: [this.authService.getUserId(), [Validators.required]],
    address: [{value: 'No address', disabled: false}, Validators.required],
    date: [this.getFormatedDate(new Date()), [Validators.required]],
    time: ['', [Validators.required]],
    duration: ['', [Validators.required]],
    usersSupported: ['', [Validators.required]],
    privateComments: ['', [Validators.nullValidator]],
    publicComments: ['', [Validators.nullValidator]],
    taskType: [1, [Validators.required]],
    tasks: this.fb.array([
    ])
  });

  model: Visit;

  // Used to disable the submit button on click
  submitting = false;

  customers: Observable<Customer[]>;
  engineers: Observable<User[]>;
  taskTypes = [{'id': 1, 'name': 'Visit'}, {'id': 2, 'name': 'Maintenance'}];


  ngOnInit() {

    // Load Customers to dropdown
    this.customers = this.customerService.getAll('active=true');
    this.customers.subscribe();

    // Load Engineers to dropdown
    this.engineers = this.usersService.getAll();
    this.engineers.subscribe();
  }

  // When the user selects a customer
  onCustomerChange(id: any) {
    // Show the customer tasks
    this.customerService.get(id).subscribe(
      res => {
        const control = <FormArray>this.form.controls['tasks'];
        this.clearFormArray(control);
        res.customerTasks.forEach(c => {
          if (c.task.taskType === this.form.get('taskType').value) {
            control.push(this.getTaskRow(c));
          }
        });
        // Update the form values
        this.form.patchValue({
          address: res.address,
          usersSupported: res.contractUsers
        });
    });
  }

  onTaskTypeChange(id: any) {
    const customer = this.form.get('customerId').value;
    this.customerService.get(customer).subscribe(
      res => {
        const control = <FormArray>this.form.controls['tasks'];
        this.clearFormArray(control);
        res.customerTasks.forEach(c => {
          if (c.task.taskType === this.form.get('taskType').value) {
            control.push(this.getTaskRow(c));
          }
        });
    });
  }

  // Returns the current date formated as yyyy-MM-dd
  private getFormatedDate(date): string {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }

    return `${year}-${month}-${day}`;
  }

  private clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  getTaskRow (task: CustomerTask) {
    const row = this.fb.group({
      taskId: [task.taskId],
      task: [task.task],
      type: [task.task.type],
      checked: [false],
      amount: [''],
      text: [''],
      taskType: [task.task.taskType],
    });
    return row;
  }

  getTaskRowEmpty () {
    const row = this.fb.group({
      taskId: [''],
      task: [''],
      type: [''],
      checked: [false],
      amount: [''],
      text: ['']
    });
    return row;
  }

  get DynamicFormTasks() {
    return <FormArray>this.form.get('tasks');
  }

  // Add the visit to the database
  onSubmit(model: Visit, isValid: boolean) {
    if (!isValid) {
      this.alertService.error('Please fill the required fields');
      return;
    }

    this.submitting = true;

    model.tasks.forEach(t => {
      // Remove the Task Object from Completed Tasks
      t.task = null;
      if (t.type === 1 || t.type === 3 || t.type === 4 ) {
        t.amount = 0;
      }
    });

    this.service.add(model).subscribe(res => {
      if (this.data.pageIndex === 0) {
        this.data.dataSource.load(this.data.pageIndex, this.data.pageSize);
      }
      this.dialogRef.close();
      this.alertService.success('Visit Added');
    },
    error => {
      this.alertService.error(error.statusText);
      this.submitting = false;
    });
  }

  // Closes the form
  onCancelClick() {
    this.dialogRef.close();
  }
}
