import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
import { Visit } from 'src/app/_models/visit';
import { Customer } from 'src/app/_models/customer';
import { User } from 'src/app/_models/user';
import { Observable } from 'rxjs';
import { CustomerService } from 'src/app/_services/customers.service';
import { UserService, AuthenticationService } from 'src/app/_services';
import { repeat } from 'rxjs/operators';
import { ScheduledTask } from 'src/app/_models/scheduledTask.model';
import { RRuleParserService } from 'src/app/_services/rruleParser.service';

@Component({
  selector: 'app-scheduled-visits-new',
  templateUrl: './scheduled-visits-new.component.html',
  styleUrls: ['./scheduled-visits-new.component.css']
})
export class ScheduledVisitsNewComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ScheduledVisitsNewComponent>,
    private customerService: CustomerService,
    private usersService: UserService,
    private authService: AuthenticationService,
    private fb: FormBuilder,
    private rRuleParser: RRuleParserService
  ) { }

  form = this.fb.group({
    title: ['', [Validators.required]],
    customerId: ['', [Validators.required]],
    type: ['visit', [Validators.required]],
    engineerId: [this.authService.getUserId(), [Validators.required]],
    address: [{value: 'No address', disabled: false}, Validators.required],
    date: [this.getFormatedDate(new Date()), [Validators.required]],
    time: ['', [Validators.required]],

    repeat: [false, [Validators.required]],
    repeatNum: [1, [Validators.required]],
    repeatDateType: ['DAILY', [Validators.nullValidator]],
    endsType: ['N', [Validators.required]],

    duration: ['', [Validators.required]],
    repeatDays: [[false, false, false, false, false, false, false], [Validators.nullValidator]],
    usersSupported: ['', [Validators.required]],
    privateComments: ['', [Validators.nullValidator]],
    publicComments: ['', [Validators.nullValidator]],
    untilDate: [this.getFormatedDate(new Date()), [Validators.required]],
    occurrences: [1, [Validators.nullValidator]]
  });

  model: any;
  customers: Observable<Customer[]>;
  engineers: Observable<User[]>;
  repeatDateTypes: any[];
  types: string[];
  // Used to show more controls when a task should be repeated
  checkedRepeat = this.form.controls['repeat'].value;
  repeatType = 'd';
  submitting = false;

  ngOnInit() {
    // Load Customers to dropdown
    this.customers = this.customerService.getAll('active=true');
    this.customers.subscribe();

    // Load Engineers to dropdown
    this.engineers = this.usersService.getAll();
    this.engineers.subscribe();

    this.repeatDateTypes =  [
      {id: 'DAILY', name: 'day(s)'}
      , {id: 'WEEKLY', name: 'week(s)'}
      , {id: 'MONTHLY', name: 'month(s)'}
      , {id: 'YEARLY', name: 'year(s)'}
    ];
    this.types = ['visit', 'maintenance'];

    this.form.get('repeat').valueChanges.subscribe(val => {
      const repeatVal = this.form.get('repeat').value;
      this.repeatClicked(repeatVal);
    });
  }

  // Closes the form
  onCancelClick() {
    this.dialogRef.close();
  }

  // Returns the current date formated as yyyy-MM-dd
  private getFormatedDate(date): string {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }

    return `${year}-${month}-${day}`;
  }

  repeatClicked(repeatVal: boolean) {
    this.checkedRepeat = repeatVal;
    if (repeatVal) {
      this.dialogRef.updateSize('700px', '880px');
    } else {
      this.dialogRef.updateSize('700px', '520px');
    }
  }

  onChange(id: any) {
    this.repeatType = this.form.get('repeatDateType').value;
    console.log(this.repeatType);
  }

  onSubmit(model: any, isValid: boolean) {
    const scheduledTask = new ScheduledTask();
    scheduledTask.customerId = model.customerId;

    const rrule = this.rRuleParser.toRRuleString(model);


    console.log(model);
    console.log(rrule);
  }

}
