import { Component, OnInit} from '@angular/core';
import { AlertService } from '../_services/alert.service';
import { MatSnackBar } from '@angular/material';

@Component({
    selector: 'app-alert',
    templateUrl: 'alert.component.html'
})

export class AlertComponent implements OnInit {
    message: any;

    constructor(private alertService: AlertService, private snackBar: MatSnackBar){}

    ngOnInit() {
        this.alertService.getMessage().subscribe(
            message => this.openSnackBar(message)
        );
    }

    openSnackBar(message) {
        this.snackBar.open(message.text, 'OK', { duration: 1500 } );
    }
}
