import { EmailGroup } from './../_models/emailGroup.model';
import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailGroupsService {

  constructor() { }

  // Returns all Email Groups.
  // It returns an observable because we may want to fetch the groups from a network resource in the future
  getAll(): Observable<EmailGroup[]> {
    const groups = [
        {id: 1, name: 'Network Dynamics'},
        {id: 2, name: 'Mycon'},
      ];
    return Observable.create((observer: Subscriber<any>) => {
        observer.next(groups);
        observer.complete();
    });
  }
}
