import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Task } from '../_models/task';
import * as globals from './../globals';

@Injectable()
export class TasksService {
    private _url = `${globals.API_URL}/tasks`;

    constructor(private http: HttpClient) { }

    // Returns all Tasks
    getAll(): Observable<Task[]> {
        return this.http.get<Task[]>(this._url);
    }

    // Returns a specific Task
    get(id: number): Observable<Task> {
        return this.http.get<Task>(`${this._url}/${id}`);
    }

    // Adds a Task
    add(task: Task): Observable<any> {
        return this.http.post(this._url, task);
    }

    // Updates a Task
    update(id: number, task: Task): Observable<any> {
        return this.http.put(`${this._url}/${id}`, task);
    }

    // Deletes a Task
    delete(id: number): Observable<any> {
        return this.http.delete(`${this._url}/${id}`);
    }
}
