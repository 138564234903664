import { Task } from './task';

export class CustomerTask {

    constructor(TaskId: number) {
        this.taskId = TaskId;
    }

    public id: number;
    public customerId: number;
    public taskId: number;
    public task: Task;
}
