import { Customer } from './customer';
import { User } from './user';

export class ScheduledTask {
    id: number;
    customerId: number;
    customer: Customer;
    engineerId: number;
    engineer: User;
    address: string;
    date: string;
    time: string;
    type: string;
    rule: string;
    exceptions: string;
}
