import { Customer } from './../../_models/customer';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { catchError, finalize, map } from 'rxjs/operators';
import { of, BehaviorSubject } from 'rxjs';
import { AlertService } from '../../_services';
import { CustomerService } from '../../_services/customers.service';
import { CustomerTasksDataSource } from '../customer-new/tasks.datasource';
import { TasksService } from '../../_services/tasks.service';
import { CustomerTask } from '../../_models/customerTask';
import { DeleteQuestionDialogComponent } from '../../delete-question-dialog/delete-question-dialog.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ValidateEmails } from '../../_helpers/EmailsValidator';
import { GroupsService } from 'src/app/_services/groups/groups.service';
import { EmailGroupsService } from 'src/app/_services/emailgroup.service';

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.css']
})
export class CustomerEditComponent implements OnInit {

  // Defininig the form
  form = new FormGroup({
    id: new FormControl('', [
      Validators.nullValidator
    ]),
    name: new FormControl('', [
      Validators.required,
    ]),
    groupId: new FormControl('', [
      Validators.nullValidator
    ]),
    address: new FormControl('', [
      Validators.nullValidator
    ]),
    emails: new FormControl('', [
      ValidateEmails
    ]),
    emailGroupId: new FormControl('', [
      Validators.nullValidator
    ]),
    contractUsers: new FormControl('', [
      Validators.required,
    ]),

    lastVisitDate: new FormControl('', [
      Validators.nullValidator
    ]),

    active: new FormControl('', [
      Validators.nullValidator
    ]),
    visits: new FormControl('', [
      Validators.nullValidator
    ]),
    recurrence: new FormControl('', [
      Validators.nullValidator
    ]),
    customerTasks: new FormControl('', [
      Validators.nullValidator
    ]),
  });

   // Used to disable the submit button on click
   submitting = false;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  checkValue: boolean;
  customerGroups = [];
  emailGroups = [];

  tasksDataSource: CustomerTasksDataSource;
  displayedColumns: string[] = ['select', 'title', 'type', 'taskType'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CustomerEditComponent>,
    private service: CustomerService,
    private tasksService: TasksService,
    private alertService: AlertService,
    private customerGroupService: GroupsService,
    private emailGroupsService: EmailGroupsService,
    public dialog: MatDialog) { }

  ngOnInit() {

    this.tasksDataSource = new CustomerTasksDataSource(this.tasksService);
    this.tasksDataSource.load();
    this.loadCustomer();
    this.loadCustomerGroups();
    this.loadEmailGroups();
  }

  loadCustomerGroups() {
    this.customerGroupService.getAll().subscribe((res) => this.customerGroups = res);
  }

  loadEmailGroups() {
    this.emailGroupsService.getAll().subscribe((res) => this.emailGroups = res);
  }

  // Loads the customer from the back-end
  loadCustomer() {
    this.loadingSubject.next(true);

        this.service.get(this.data.id)
        .pipe(
            catchError(() => of()),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe(
            (customer: Customer) => {
              const taskIds: number[] = customer.customerTasks.map(
                function(item) {
                  const ids = item.taskId;
                  return ids;
              });
              this.tasksDataSource.toggleSpecific(taskIds);
              this.form.setValue(customer);
            }
        );
  }

  // Submits the customer and his tasks
  onSubmit(model: Customer, isValid: boolean) {

    if (!isValid) {
      this.alertService.error('Please fill the required fields');
      return;
    }

    this.submitting = true;
    model.customerTasks = [];

    // Add only assigned Tasks to Customer model
    this.tasksDataSource.selection.selected.forEach(
      i => {
        if (i !== undefined) {
          const task = new CustomerTask(i.id);
          task.customerId = model.id;
          model.customerTasks.push(task);
        }
      },
    );

    this.service.update(model.id, model).subscribe(res => {
      this.alertService.success('Customer Updated');
      this.data.dataSource.load();
      this.dialogRef.close();
    },
    error => {
      this.alertService.error(error);
      this.submitting = false;
    });
  }

  // Close this dialog window
  onCancelClick() {
    this.dialogRef.close();
  }

  // Deletes a customer if he has no visits
  onDeleteClick(model: Customer) {

    // If customer has visits, don't delete him
    if (model.visits > 0) {
      this.alertService.error('Cannot delete a customer that has been visited.');
      return;
    }

    // Open the question dialog
    const dialogRef = this.dialog.open(DeleteQuestionDialogComponent, {
      width: '250px',
      data: {
        id: model.id,
        dataSource: this.data.dataSource,
        service: this.service,
        dialog: this.dialogRef
      }
    });
  }
}
