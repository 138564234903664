import { AbstractControl } from '@angular/forms';

export function ValidateEmails(control: AbstractControl) {

    if (control.value === '') {
        return null;
    }
    // split the string into mails
    const emails: string[] = control.value.split(';');
    // regex for email check
    const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    // email validation function
    function validate(email) {
        return re.test(email.toLowerCase());
    }

    // check each email
    let oneIsInvalid = false;
    emails.forEach(m => {
        const valid = validate(m);
        if (!valid) {
            oneIsInvalid = true;
        }
    });

    // if at least one is invalid return error
    if (oneIsInvalid) {
        return { invalidMails: true };
    }
    return null;
}
