import { BehaviorSubject, Observable, of } from 'rxjs';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import { CustomerService } from '../../_services/customers.service';
import { catchError, finalize } from 'rxjs/operators';
import { Customer } from '../../_models/customer';

export class CustomersDataSource implements DataSource<Customer> {
    private customersSubject = new BehaviorSubject<Customer[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    constructor(private service: CustomerService) {

    }

    connect(collectionViewer: CollectionViewer): Observable<Customer[]> {
        return this.customersSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.customersSubject.complete();
        this.loadingSubject.complete();
    }

    load() {
        this.loadingSubject.next(true);

        this.service.getAll()
        .pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe(
            customers => this.customersSubject.next(customers)
        );
    }
}
