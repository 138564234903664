import { Component, OnInit, Inject } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { VisitsService } from '../../_services/visits.service';
import { AlertService } from '../../_services';
import { catchError, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-visits-edit',
  templateUrl: './visits-edit.component.html',
  styleUrls: ['./visits-edit.component.css']
})
export class VisitsEditComponent implements OnInit {

  model;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<VisitsEditComponent>,
    private service: VisitsService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.loadVisit();
  }

  loadVisit() {
    this.loadingSubject.next(true);

    this.service.get(this.data.id)
    .pipe(
        catchError(() => of()),
        finalize(() => this.loadingSubject.next(false))
    )
    .subscribe(
        visit => { this.model = visit; }
    );
  }

  onCancelClick() {
    this.dialogRef.close();
  }

}
