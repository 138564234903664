import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'showFirstMail'})
export class ShowFirstMail implements PipeTransform {
    transform(value: string): string {
        const i = value.indexOf(';');
        if (i === -1) { return value; }
        return value.substr(0, i);
    }
}
