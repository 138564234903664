import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TasksEditComponent } from '../../tasks/tasks-edit/tasks-edit.component';
import { TasksService } from '../../_services/tasks.service';
import { AlertService, UserService, AuthenticationService } from '../../_services';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { User } from '../../_models/user';

@Component({
  selector: 'app-users-edit',
  templateUrl: './users-edit.component.html',
  styleUrls: ['./users-edit.component.css']
})
export class UsersEditComponent implements OnInit {

  // Defininig the form
  form = new FormGroup({
    id: new FormControl('', [
      Validators.required,
    ]),
    firstName: new FormControl('', [
      Validators.required,
    ]),
    lastName: new FormControl('', [
      Validators.required
    ]),
    groupId: new FormControl('', [
      Validators.required
    ]),
    email: new FormControl('', [
      Validators.required,
    ]),
    password: new FormControl('', [
      Validators.nullValidator
    ]),
    active: new FormControl('true', [
      Validators.nullValidator
    ]),
    userImageUrl: new FormControl('', [
      Validators.nullValidator
    ])
  });

  // Used to disable the submit button on click
  submitting = false;

  isAdmin: boolean;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  userGroups = [
    {id: 0, name: 'Admin'},
    {id: 1, name: 'User'},
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TasksEditComponent>,
    private service: UserService,
    private authService: AuthenticationService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.isAdmin = this.authService.idAdmin();
    this.loadUser();
  }

  // Load the user from the back-end
  loadUser() {
    this.loadingSubject.next(true);

    this.service.get(this.data.id)
    .pipe(
        catchError(() => of()),
        finalize(() => this.loadingSubject.next(false))
    )
    .subscribe(
        user => {
           this.form.setValue(user);
    });
  }

  // Updates the User
  onSubmit(model: User, isValid: boolean) {

    if (!isValid) {
      this.alertService.error('Please fill the required fields');
      return;
    }

    this.submitting = true;

    this.service.update(model).subscribe(res => {
      this.alertService.success('User Updated');
      this.data.dataSource.load();
      this.dialogRef.close();
    },
    error => {
      this.alertService.error(error._body);
      this.submitting = false;
    });
  }

  // Close this dialog window
  onCancelClick() {
    this.dialogRef.close();
  }
}
