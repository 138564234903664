import { Admin } from './../_models/admin';
import { AdminService } from './../_services/admin.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { AlertService } from '../_services';
import { ValidateEmails } from '../_helpers/EmailsValidator';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  // Defining the form
  form = new FormGroup({
    stakeHolderMails: new FormControl('', [
      ValidateEmails
    ]),
    maxNotVisitedDays: new FormControl('', [
      Validators.nullValidator,
    ]),
    escalationEmails: new FormControl('', [
      ValidateEmails
    ]),
    id: new FormControl('', [
      Validators.nullValidator
    ]),
  });

  constructor(
    private service: AdminService,
    private alertService: AlertService
    ) { }

  ngOnInit() {
    this.service.get()
        .pipe(
            catchError(() => of()),
            // finalize()
        )
        .subscribe(
            (admin: Admin) => {
              this.form.setValue(admin);
            }
        );
  }

  onSubmit(model: Admin, isValid: boolean) {

    if (!isValid) {
      this.alertService.error('Please fix all the form errors');
      return;
    }

    this.service.update(model).subscribe(res => {
      this.alertService.success('Admin panel Updated');
    },
    error => {
      this.alertService.error(error);
      // this.submitting = false;
    });
  }

}
