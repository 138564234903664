import { Component, OnInit, Inject, ViewChild, Type } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import * as globals from '../../globals';
import { HttpClient } from '@angular/common/http';
import { ImageCropperComponent, CropperSettings, Bounds } from 'ng2-img-cropper';
import { AlertService, AuthenticationService } from '../../_services';

@Component({
  selector: 'app-imageupload',
  templateUrl: './imageupload.component.html',
  styleUrls: ['./imageupload.component.css']
})
export class ImageuploadComponent {

  private _url = `${globals.API_URL}/UserImage/SaveFile`;
  public cropperSettings: CropperSettings;

  // Used to disable the submit button on click
  public submitting = false;
  public cropperdata: any;

  @ViewChild('cropper', undefined) cropper: ImageCropperComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ImageuploadComponent>,
    private http: HttpClient,
    private alertService: AlertService,
    private authService: AuthenticationService
  ) {
    this.setCropperSettings();
  }

  setCropperSettings() {
    this.cropperSettings = new CropperSettings();
    this.cropperSettings.width = 140;
    this.cropperSettings.height = 140;

    this.cropperSettings.croppedWidth = 140;
    this.cropperSettings.croppedHeight = 140;

    this.cropperSettings.canvasWidth = 500;
    this.cropperSettings.canvasHeight = 300;

    this.cropperSettings.minWidth = 100;
    this.cropperSettings.minHeight = 100;

    this.cropperSettings.rounded = true;
    this.cropperSettings.keepAspect = true;

    this.cropperSettings.cropperDrawSettings.strokeColor = 'rgba(255,255,255,1)';
    this.cropperSettings.cropperDrawSettings.strokeWidth = 2;

    this.cropperdata = {};
  }

  cropped(bounds: Bounds) {
  }

  fileChangeListener($event) {
    const image: any = new Image();
    const file: File = $event.target.files[0];
    const myReader: FileReader = new FileReader();
    const that = this;
    myReader.onloadend = function (loadEvent: any) {
        image.src = loadEvent.target.result;
        that.cropper.setImage(image);
    };

    myReader.readAsDataURL(file);
  }

  onUpload() {
    this.submitting = true;
    this.UploadFile(this.cropperdata.image);
  }

  UploadFile(file: File) {
    const formData = new FormData();
    const blob = this.imagetoblob(file);
    formData.append('userimage', blob, 'userImage');
    this.http.post(this._url, formData).subscribe((res: any) => {
      this.alertService.success('File uploaded successfully');
      this.authService.setImage(res.uri);
      this.dialogRef.close();
    },
    error => {
      this.alertService.error(error);
      this.submitting = false;
    });
  }

  b64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    const sliceSize = 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }


  imagetoblob(imgBase64) {
    // Split the base64 string in data and contentType
    const block = imgBase64.split(';');
    // Get the content type of the image
    const contentType = block[0].split(':')[1];
    // get the real base64 content of the file
    const realData = block[1].split(',')[1];

    // Convert it to a blob to upload
    return this.b64toBlob(realData, contentType);
  }

  onCancelClick() {
    this.dialogRef.close();
  }

}
