import { DataSource } from '@angular/cdk/table';
import { Task } from '../../_models/task';
import { CollectionViewer, SelectionModel } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { TasksService } from '../../_services/tasks.service';
import { catchError, finalize } from 'rxjs/operators';


export class CustomerTasksDataSource implements DataSource<Task> {

    private tasksSubject = new BehaviorSubject<Task[]>([]);
    public selection = new SelectionModel<Task>(true, []);
    private elementsCount: number;

    constructor(private service: TasksService) { }

    connect(collectionViewer: CollectionViewer): Observable<Task[]> {
        return this.tasksSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.tasksSubject.complete();
    }

    load(selectAll = false) {
        this.service.getAll()
        .pipe(
            catchError(() => of([]))
        )
        .subscribe (
            tasks => {
                this.tasksSubject.next(tasks);
                this.elementsCount = tasks.length;
                if (selectAll) {
                    this.masterToggle();
                }
            }
        );
    }

    /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    return numSelected === this.elementsCount;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.tasksSubject.value.forEach(row => this.selection.select(row));
  }

  // Select specific elements given a list of IDs
  toggleSpecific(ids: number[]) {
    this.tasksSubject.subscribe(t => {
        ids.forEach(
            id => {
                const taskRow = t.find(row => row.id === id);
                this.selection.select(taskRow);
            }
        );
    });
  }
}
